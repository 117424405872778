:root {
  --primaryColor: #036fef;
  --primaryBoldColor: #001848;
  --primaryBlackColor: #01030f;
  --secondaryColor: #fff;
  --opaqueBackground: #000000e6;
  --mainCanvasBackground: #141414;
  --whiteColor: #fff;
  --primaryTextSize: 15px;
  --primaryTextSizeS: 10px;
  --letterSpacing: 5px;
  --radius: 4px;
  --contentTransitionDuration: 600ms;
  --contentTransitionBezier: cubic-bezier(0.83, 0.39, 0.21, 0.96);
}

body {
  background-image: url(../public/images/background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50%;
  background-attachment: fixed;
}

/* BUTTONS */
.button {
  position: relative;
  border: none;
  background-color: transparent;
  padding: 15px 30px;
  letter-spacing: var(--letterSpacing);
  font: 700 var(--primaryTextSize) Sora, sans-serif;
  border-radius: var(--radius);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.button__image {
  width: 20px;
  filter: invert(100%);
}
.button__text {
  z-index: 1;
}

.button--primary {
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
}
.button--primary::before,
.button--primary::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  content: "";
  z-index: -1;
  transition: width 400ms ease;
}
.button--primary:hover::before {
  width: 100%;
  transition: width 300ms ease;
  background-color: var(--primaryBoldColor);
}
.button--primary:hover::after {
  width: 20px;
  transition: width 200ms 200ms ease;
  background-color: var(--primaryBlackColor);
}

/* Title */

.title {
  @apply my-10;
  position: relative;
  color: var(--secondaryColor);
  font: 800 40px Sora, sans-serif;
  transform: translate3d(0, 0, 0);
  transition: var(--contentTransitionDuration) 300ms
    var(--contentTransitionBezier);
}

.text {
  width: 70%;
  color: var(--secondaryColor);
  font: 600 20px Sora, sans-serif;
  margin-bottom: 50px;
  transform: translate3d(0, 0, 0);
  transition: var(--contentTransitionDuration) 500ms
    var(--contentTransitionBezier);
}

.footer {
  color: var(--secondaryColor);
  font: 400 var(--primaryTextSize) Sora, sans-serif;
}
